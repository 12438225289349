<template>
  <div class="app-body">
    <div class="a-flex-rsbc a-ptb-15">
      <span class="a-fs-16 a-fw-b">用户管理</span>
      <le-export-btn :page-param="pageParam"></le-export-btn>
    </div>
    <el-card class="el-main">
      <le-search-form @reset="handlerRest" @search="handlerSearch">
        <le-input v-model="pageParam.params.mobile" type="number" label="手机号" large />
        <le-date-range
          label="注册时间"
          :min-date.sync="pageParam.params.createTimeStart"
          :max-date.sync="pageParam.params.createTimeEnd"
          large
        />
      </le-search-form>
      <le-pagview :page-param="pageParam" @setData="setTableData">
        <el-table
          :data="tableData"
          :highlight-current-row="true"
          style="width: 100%"
          max-height="500"
        >
          <el-table-column prop="nickName" label="用户昵称" width="180"></el-table-column>
          <el-table-column prop="mobile" label="手机号" width="200"></el-table-column>
          <el-table-column label="是否实名" width="120">
            <template slot-scope="{ row }">{{ row.isOcr ? "是" : "否" }}</template>
          </el-table-column>
          <el-table-column label="余额(现金/红包/赠送金)">
            <template slot-scope="{ row }">
              &yen;{{ util.numFormat(row.balance) }}/&yen;{{
                util.numFormat(row.activityBalance)
              }}/&yen;{{ util.numFormat(row.giftBalance) }}/
            </template>
          </el-table-column>
          <el-table-column prop="lastLoginTimeText" label="最后登录时间"></el-table-column>
          <el-table-column prop="createTimeText" label="注册时间"></el-table-column>
          <el-table-column fixed="right" label="操作" class="a-flex-rcc" width="100">
            <template slot-scope="{ row }">
              <el-tooltip class="item" effect="dark" content="详情" placement="top">
                <span @click="handlerGoDetail(row.id)">
                  <img src="../../assets/icon/option-detail.png" class="a-wh-16" />
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </le-pagview>
    </el-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

import util from "../../utils/util.js";
export default {
  name: "UserMGTList",

  data() {
    return {
      util: util,
      tableData: [],
      pageParam: {
        url: this.$Config.apiUrl.getUserManagerList,
        method: "post",
        params: {
          mobile: "",
          createTimeStart: "",
          createTimeEnd: "",
        },
        freshCtrl: 1,
      },
    };
  },
  computed: {
    ...mapState({
      company: (state) => state.company.company,
    }),
  },
  mounted() {},
  methods: {
    //获取用户列表
    setTableData(data) {
      this.tableData = data;
    },

    handlerRest() {
      this.pageParam.params = {
        mobile: "",
        createTimeStart: "",
        createTimeEnd: "",
      };
      this.pageParam.freshCtrl++;
    },

    handlerSearch() {
      this.pageParam.freshCtrl++;
    },
    //跳转详情
    handlerGoDetail(id) {
      this.$router.push({
        path: "/userMGT/userMGT-detail",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style scoped></style>
